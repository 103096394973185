import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//成交分析区域 数据
export function detail(params) {
	return loginRequest({
    url: '/frontend/website/about-us/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}